<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

      <div class="d-sm-flex d-none user-nav mr-2">
        <h6 class="text-right user-name font-weight-bolder mb-0">
          <span style="font-size: 12pt; line-height: 14pt;">{{ user.name }}</span><br>
          <span style="font-size: 10pt; line-height: 6pt;">{{ user.cargo }}</span>
        </h6>
      </div>
      <div class="btn btn-danger px-1" onmouseover="$('#socorro').show(300);" onmouseleave="$('#socorro').hide(300);" @click="socorro()">
        <i class="fas fa-exclamation-triangle"></i>
        <span class="ml-1" id="socorro" style="display: none;">Socorro</span>
      </div>

    <b-navbar-nav class="nav align-items-center ml-auto ml-1">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="user.photo"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>
        <b-dropdown-item link-class="d-flex align-items-center" data-toggle="modal" data-target="#finaliza" @click="removeBg()">
          <feather-icon size="16" icon="KeyIcon" class="mr-50" />
          <span>Fechar dia</span>
        </b-dropdown-item>
        <b-dropdown-item link-class="d-flex align-items-center" @click="mYProfile()">
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Meu Perfil</span>
        </b-dropdown-item>

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="MailIcon" class="mr-50" />
          <span>Inbox</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="CheckSquareIcon" class="mr-50" />
          <span>Task</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="MessageSquareIcon" class="mr-50" />
          <span>Chat</span>
        </b-dropdown-item> -->

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Sair</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>

    <div class="modal fade" id="finaliza" aria-labelledby="title" aria-hidden="false">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header bg-primary">
            <h5 class="modal-title text-light" id="title">Reiniciar senhas</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center p-1">
              <h5>Tem certeza que deseja reiniciar as senhas?</h5>
          </div>
          <div class="modal-footer text-center p-1">
            <button class="btn btn-secondary" data-dismiss="modal" aria-label="Close">Cancelar</button>
            <button class="btn btn-primary" data-dismiss="modal" aria-label="Close" @click="resetSenhas()">Fechar</button>
          </div>
        </div>
      </div>
    </div>  
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import router from "@/router";

export default {
  data() {
    return {
      authenticated: false,
      disabled: true,
      user: {},
    };
  },
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    logout: function () {
      let self = this;
      let api = this.$store.state.api + "logout";

      self.$http
        .post(api)
        .then((response) => {
          localStorage.removeItem("token");

          self.$router.push({ path: "/login" });

          setTimeout(() => {
            location.reload();
          }, 500)
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.$message("Erro", "Erro ao realizar logout", "error");
        });
    },
    socorro: function(){
      let self = this;
      let api = this.$store.state.api + "panicos";

      if(!self.$store.state.user.local)
      {
        self.$store.state.user.local = 0;
      }

      self.$http
        .post(api, {usuario_id: self.$store.state.user.id, local_id: self.$store.state.user.local})
        .then((response) => {
          this.$message(null, "Mantenha calma, a ajuda está a caminho", "warning");
        })
        .catch((error) => {
          this.$message("Erro", "Falha ao solicitar ajuda", "error");
        });
    },
    mYProfile: function(){
      const self = this;
      let id = self.$store.state.user.id;
      router.push('/users/edit/' + id);
    },
    resetSenhas: function(){
      let self = this;
      let api = this.$store.state.api + "reset-senhas";

      self.$http
        .post(api, [])
        .then(() => {
          this.$router.push("/");

          this.$message(null, "Contagem de senhas zerada com sucesso!!!", "success");
        })
        .catch((error) => {
          this.$message("Erro", "Falha ao reiniciar o dia", "error");
        });
    },
    removeBg: function(){
      setTimeout(() => {
        $('.modal-backdrop').addClass('d-none');
      }, 500);
    }
  },
  mounted() {
    const self = this;
    setTimeout(function () {
      self.user = self.$store.state.user;
      console.log(self.$store.state.user)
      if (self.user.password_expired == 1) {
        $("#modalChangePassword").modal({
          backdrop: "static",
          keyboard: false, // to prevent closing with Esc button (if you want this too)
        });
      }
    }, 100);
  },
};
</script>
