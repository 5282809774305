export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    perfil_id: [1, 2, 6, 7, 8],
  },
  {
    title: 'Agenda',
    route: 'calendar-agendamentos',
    icon: 'CalendarIcon',
    perfil_id: [6],
  },
  {
    title: 'Cadastros',
    icon: 'FileTextIcon',
    perfil_id: [6],
    children: [
      {
        title: 'Botões',
        route: 'list-botoes',
        perfil_id: [6],
      },
      {
        title: 'CBO`s',
        route: 'list-cbos',
        perfil_id: [6],
      },
      {
        title: 'Finalizações',
        route: 'list-finalizacoes',
        perfil_id: [6],
      },
      {
        title: 'Grupos Prioritários',
        route: 'list-classificacoes',
        perfil_id: [6],
      },
      {
        title: 'Locais',
        route: 'list-locais',
        perfil_id: [6],
      },
      {
        title: 'Mensagens',
        route: 'list-mensagens',
        perfil_id: [6],
      },
      {
        title: 'Paineis',
        route: 'list-paineis',
        perfil_id: [6],
      },
      {
        title: 'Setores',
        route: 'list-setores',
        perfil_id: [6],
      },
      {
        title: 'Simbolos',
        route: 'list-simbolos',
        perfil_id: [6],
      },
      {
        title: 'Totens',
        route: 'list-totens',
        perfil_id: [6],
      },
      {
        title: 'Unidades',
        route: 'list-unidades',
        perfil_id: [6],
      },
      {
        title: 'Usuários',
        route: 'list-users',
        perfil_id: [6],
      },
      {
        title: 'Vídeos',
        route: 'list-videos',
        perfil_id: [6],
      },
    ],
  },
  {
    title: "Indicadores",
    route: "indicadores",
    icon: "PieChartIcon",
    perfil_id: [6],
  },
  {
    title: "Chamar senhas",
    route: "chamador",
    icon: "Volume2Icon",
    perfil_id: [1, 2, 6, 7, 8],
  },
  {
    title: "Senhas",
    route: "list-senhas",
    icon: "HeartIcon",
    perfil_id: [1, 2, 6, 7, 8],
  },
  {
    title: "Configurações",
    route: "list-configuracoes",
    icon: "SettingsIcon",
    perfil_id: [1, 2, 6, 7, 8],
  },
]
